import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';
import {hotelsTestIds} from 'airborne/hotels/testIds';
import Loader from 'airborne/search2/Loader';
import getHotelDetails from 'airborne/store/modules/search_hotels/actions/hotelDetails';

import HotelTab from './HotelTab';

function mapStateToProps({hotels}, {id}) {
    const {loading, data} = hotels.hotelDetails;
    const hotel = data[id];
    if (!hotel) {
        return {known: false, loading: Boolean(loading[id])};
    }
    return {
        loading: Boolean(loading[id]),
        known: true,
        description: hotel.description || hotel['short_description'],
        phone: hotel.phone,
        fax: hotel.fax,
    };
}

@connect(mapStateToProps, {getHotelDetails})
export default class About extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        loading: PropTypes.bool,
        known: PropTypes.bool,
        description: PropTypes.string,
        phone: PropTypes.string,
        fax: PropTypes.string,
        getHotelDetails: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const {id, known} = this.props;
        if (!known) {
            this.props.getHotelDetails(id);
        }
    }

    render() {
        const {id, loading, description, phone, fax} = this.props;
        return (
            <HotelTab id={id} title={gettext('About')} testId={hotelsTestIds.results.hotelsTabsContentAbout}>
                <div className="hotel-details">
                    {loading && <Loader top />}
                    <div className="hotel-details__section" dangerouslySetInnerHTML={{__html: description}} />
                </div>
                <p>
                    <b>{gettext('Contact')}</b> <br />
                    {gettext('Phone number')}: {phone || '—'} <br />
                    {gettext('Fax number')}: {fax || '—'}
                </p>
            </HotelTab>
        );
    }
}
