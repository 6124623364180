import PropTypes from 'prop-types';
import React from 'react';

import {hotelsTestIds} from 'airborne/hotels/testIds';

import About from './About';
import Facilities from './Facilities';
import Photos from './Photos';
import Policies from './Policies';
import RateList from './RateList';
import Map from './maploader';

const TABS = {
    rates: RateList,
    facilities: Facilities,
    map: Map,
    policies: Policies,
    about: About,
    photos: Photos,
};

export default class HotelTabs extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        selected: PropTypes.string,
        searchPosition: PropTypes.number,
    };

    render() {
        const {selected, id, searchPosition} = this.props;
        const Tab = TABS[selected];

        return (
            <div className="h-item__tab-area" data-testid={hotelsTestIds.results.hotelTabs}>
                <Tab id={id} searchPosition={searchPosition} />
            </div>
        );
    }
}
