import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import dataset from 'airborne/bookings2/helpers/dataset';
import gettext from 'airborne/gettext';
import {hotelsTestIds} from 'airborne/hotels/testIds';

function addHeapAttr(props, label, isAlternative) {
    if (!isAlternative) {
        return props;
    }

    return {...props, [`data-alternative-${label}`.toLowerCase()]: true};
}

function tabLink(options) {
    const {tab, label, selectedTab, isAlternative, testId} = options;
    const selected = tab === selectedTab;
    const cls = classnames({
        'hotel-card__tab--selected': selected,
    });
    const props = {
        className: cls,
        'data-tab': tab,
    };
    return (
        <a href="#" data-testid={testId} {...addHeapAttr(props, label, isAlternative)}>
            {label}
        </a>
    );
}

class Tabs extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        isAlternative: PropTypes.bool,
    };

    handleClick = event => {
        event.preventDefault();
        const {tab} = dataset(event.target);
        this.props.onChange(tab);
    };

    render() {
        const {value, isAlternative} = this.props;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        const cls = classnames('h-item__tabs', {
            'h-item__tabs--active': Boolean(value),
        });
        return (
            <nav className={cls} onClick={this.handleClick}>
                {tabLink({
                    tab: 'rates',
                    label: gettext('Rates'),
                    selectedTab: value,
                    isAlternative,
                    testId: hotelsTestIds.results.hotelTabsHeaderRates,
                })}
                {tabLink({
                    tab: 'facilities',
                    label: gettext('Facilities'),
                    selectedTab: value,
                    testId: hotelsTestIds.results.hotelTabsHeaderFacilities,
                })}
                {tabLink({
                    tab: 'policies',
                    label: gettext('Policies'),
                    selectedTab: value,
                    testId: hotelsTestIds.results.hotelTabsHeaderPolicies,
                })}
                {tabLink({
                    tab: 'about',
                    label: gettext('About'),
                    selectedTab: value,
                    testId: hotelsTestIds.results.hotelTabsHeaderAbout,
                })}
                {tabLink({
                    tab: 'photos',
                    label: gettext('Photos'),
                    selectedTab: value,
                    testId: hotelsTestIds.results.hotelTabsHeaderPhotos,
                })}
                {!isIE11 &&
                    tabLink({
                        tab: 'map',
                        label: gettext('Map'),
                        selectedTab: value,
                        testId: hotelsTestIds.results.hotelTabsHeaderMap,
                    })}
            </nav>
        );
    }
}

export default class HotelTabsHeader extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        selected: PropTypes.string,
        children: PropTypes.any,
        onSelect: PropTypes.func.isRequired,
        isAlternative: PropTypes.bool,
    };

    render() {
        const {selected, isAlternative} = this.props;

        return (
            <div>
                {this.props.children}
                <Tabs onChange={this.props.onSelect} value={selected} isAlternative={isAlternative} />
            </div>
        );
    }
}
