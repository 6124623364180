import gettext from 'airborne/gettext';
import ngettext from 'airborne/gettext';
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import {selectHotel, sortHotels, paginateHotels, clearFilters} from '../store/modules/search_hotels/actions/filters';
import {hotelLabeling} from 'airborne/search2/helpers/hyatt';
import {loadMore} from '../store/modules/search_hotels/actions/search';

import normalize from 'airborne/search2/helpers/normalize';
import {paginateHotels as paginate, destSingleHotel, isFiltered, allSameCountry} from 'airborne/search2/helpers/hotels';
import {isLoading} from 'airborne/search2/helpers/avail';

import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';


import HotelCard from './hotel/HotelCard';
import {LoaderSection, FloatLoader} from './LoaderSection';
import AftSorters from './AftSorters';
import Pager from './Pager';
import hotelSelector, {canShowAlternativeHotel} from 'airborne/search2/helpers/hotelSelector';
import {showOconusPerDiemsLink} from 'airborne/search2/helpers/perDiems';
import {isTyEnabled} from 'airborne/search2/helpers/hyatt';
import Ads from 'airborne/search2/Ads';


class PerDiemsLink extends React.Component {
    render() {
        return (
            <Dropdown className="pull-right">
                <Dropdown.Toggle id="per-diems" className="btn-xs btn-flat">
                    {gettext('View Per Diems for ...')}
                </Dropdown.Toggle>
                <DropdownMenu>
                    <DropdownItem href="http://www.defensetravel.dod.mil/site/perdiemCalc.cfm" target="_blank">
                        {gettext('AL, HI, and other US territories')}
                    </DropdownItem>
                    <Dropdown.Divider />
                    <DropdownItem href="https://aoprals.state.gov/web920/per_diem.asp" target="_blank">
                        {gettext('International Travel')}
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}
const GenericError = ()=> (
    <h4>
        {gettext('Sorry, there are no hotels that match your filters.')}
        <br/>
        {gettext('Please change your filter criteria to see more hotels.')}
    </h4>
);

const mapStateToProps = createSelector(
    [
        hotelSelector,
        (state)=> state.hotels.order,
        (state)=> state.hotels.pagination,
        (state)=> state.hotels.filters,
        (state)=> state.hotels.hotels.total,
        (state)=> state.hotels.pinnedId,
        (state)=> state.hotels.hotels.loading,
        (state)=> state.hotels.avail.loading,
        (state)=> state.hotels.hotels.errors,
        (state)=> state.hotels.selected,
        showOconusPerDiemsLink,
        isTyEnabled,
        destSingleHotel,
        canShowAlternativeHotel,
        hotelLabeling
    ],
    (list, order, pagination, filters, total, pinnedId, loadingHotels, loadingAvail, errors, selected, showPerDiems, showTrustYou, searchSingleHotel, canShowAlternativeHotel, labeling)=> {
        const {paged, params} = paginate(list, filters.value, order, pagination, pinnedId, searchSingleHotel);

        return {
            loadingHotels,
            loadingAvail,
            errors,
            selected,
            pinnedId,
            pagination: params,
            hotels: paged.map(normalize),
            order,
            total,
            hasFilters: isFiltered(filters.value, searchSingleHotel),
            hasMore: total > list.length,
            showPerDiems,
            showTrustYou,
            showCountry: !allSameCountry(list),
            canShowAlternativeHotel,
            labeling
        };
    }
);

@connect(mapStateToProps, {selectHotel, sortHotels, paginateHotels, clearFilters, loadMore})
export default class Hotels extends React.Component {
    static propTypes = {
        loadingHotels: PropTypes.bool.isRequired,
        loadingAvail: PropTypes.object.isRequired,
        selected: PropTypes.object.isRequired,
        pinnedId: PropTypes.number,
        hotels: PropTypes.array,
        errors: PropTypes.arrayOf(PropTypes.string),
        total: PropTypes.number,
        hasMore: PropTypes.bool,
        hasFilters: PropTypes.bool,
        order: PropTypes.string,
        pagination: PropTypes.shape({
            'start_index': PropTypes.number.isRequired,
            'end_index': PropTypes.number.isRequired,
            'total': PropTypes.number.isRequired,
            'page': PropTypes.number.isRequired,
            'total_pages': PropTypes.number.isRequired,
        }),
        selectHotel: PropTypes.func.isRequired,
        sortHotels: PropTypes.func.isRequired,
        paginateHotels: PropTypes.func.isRequired,
        clearFilters: PropTypes.func.isRequired,
        loadMore: PropTypes.func.isRequired,
        showPerDiems: PropTypes.bool.isRequired,
        showTrustYou: PropTypes.bool.isRequired,
        showCountry: PropTypes.bool.isRequired,
        canShowAlternativeHotel: PropTypes.bool.isRequired,
        labeling: PropTypes.object,
    };

    getId(index) {
        return {
            7: '81870',
            14: '81871',
            21: '81872',
            28: '81873',
        }[index] || null;
    }

    renderNumbers(quantity, total, showPerDiems, hasFilters) {
        if (total === 0) {
            return null;
        }

        return (<div className="section-wrapper">
            <div className="col">
                <span className="section-wrapper__attn">
                    {ngettext('{num} of {total} hotel', '{num} of {total} hotels', total, {num: quantity, total})}
                </span>
            </div>
            <div className="col">
                {hasFilters && (<Button variant="link" size="sm" className="no-padding" onClick={this.props.clearFilters} >{gettext('× Clear all filters')}</Button>)}
            </div>

            {this.renderAftSorters()}
            {showPerDiems && <PerDiemsLink />}

        </div>);
    }

    renderErrors(errors) {
        return (errors && errors.length)
            ? (<h4>{errors}</h4>)
            : (<GenericError />);
    }

    renderNoHotels(filters) {
        const {loadingHotels, errors} = this.props;
        return (<div className="section-wrapper__message">
            {!loadingHotels && this.renderErrors(errors)}
            {filters && (<Button variant="link" className="no-padding" onClick={this.props.clearFilters} >{gettext('Clear all filters')}</Button>)}
        </div>);
    }

    renderHotels() {
        const {hotels, loadingAvail, selected, pinnedId, total, showTrustYou, showCountry, canShowAlternativeHotel, labeling} = this.props;
        if (hotels.length === 0) {
            return this.renderNoHotels(total > 0);
        }
        return (hotels.map((hotel, index)=> (
            <Fragment key={hotel.id}>
                <Ads id={this.getId(index)} />
                <HotelCard
                    canShowAlternativeHotel={canShowAlternativeHotel}
                    searchPosition={index+1}
                    loading={Boolean(loadingAvail[hotel.id])}
                    selected={selected[hotel.id] || ((hotel.id === pinnedId) && 'rates') || null}
                    pin={hotel.id === pinnedId}
                    showTrustYou={showTrustYou}
                    showCountry={showCountry}
                    labeling={labeling}
                    {...hotel}
                    onSelect={this.props.selectHotel} />
            </Fragment>
        )));
    }

    renderAftSorters() {
        const {order} = this.props;
        return (<AftSorters onChange={this.props.sortHotels} value={order} />);
    }

    renderPager(params) {
        return (<Pager {...params} onChange={this.props.paginateHotels} />);
    }

    renderLoadMore() {
        const {loadingHotels, loadMore} = this.props;
        return (
            <div className="section-wrapper">
                <em className="pull-left section-wrapper__line-text">
                    {gettext('Additional hotels may be available on the dates selected.')}
                </em>
                <Button variant="info" className="pull-right" onClick={loadMore} disabled={loadingHotels}>
                    {loadingHotels ? gettext('Loading...') : gettext('Load More')}
                </Button>
            </div>
        );
    }

    render() {
        const {
            loadingHotels, loadingAvail,
            hotels, selected,
            pagination,
            total, hasMore, hasFilters,
            showPerDiems,
        } = this.props;
        const isLoadingAvail = isLoading(loadingAvail);
        const showLoader = loadingHotels || isLoadingAvail;

        return (<div>
            {showLoader
                ? (<LoaderSection hotels={loadingHotels} avail={isLoadingAvail}>{this.renderAftSorters()}</LoaderSection>)
                : this.renderNumbers(pagination.total, total, showPerDiems, hasFilters)
            }
            <Ads id="81869" />

            <div className="section-wrapper">
                {this.renderHotels(hotels, loadingAvail, selected, total)}
                {this.renderPager(pagination)}
            </div>
            {hasMore && this.renderLoadMore()}
            <FloatLoader hotels={loadingHotels} avail={isLoadingAvail} showLoader={showLoader} />
        </div>);
    }
}